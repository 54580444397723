<template>
    <div style="height: 500px;">
        <div style="width: 30%;height: 100%;float: left;">
            <div style="margin-left: 20px;margin-top: 20px;margin-bottom: 30px;">
                <template>
                    <el-select v-model="value" placeholder="请选择银行卡">
                        <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.bankName"
                                :value="item.tokenNo">
                        </el-option>
                    </el-select>
                </template>
            </div>

            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input
                        type="number"
                        placeholder="充值金额"
                        v-model="input"
                        clearable>
                </el-input>
            </div>

            <div style="width: 500px;height: 80px;margin-left: 20px;margin-top: 20px;">
                <el-input style="width: 150px;" v-model="code" placeholder="请输入验证码"></el-input>
                <el-button @click="spendCode" size="mini" round>发送验证码</el-button>
            </div>

            <div style="margin-left: 30px;">
                <el-button type="primary" @click="bankPay">立即充值</el-button>
            </div>
        </div>


        <div style="width: 65%;height: 100%;float: left;margin-left: 50px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="支付日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="300">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="支付金额"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="支付状态"
                            width="160">
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "adapay_pay",
        data(){
            return{
                options: [],
                value: '',
                input:'',
                code:'',
                orderNo:'',
                tableData: []
            }
        },
        mounted() {
            this.getAdaPayBanCardList();
            this.getAdaPayBanCardPayList();
        },
        methods:{
            getAdaPayBanCardList(){
                var that = this;
                var params = {};
                that.newApi.getAdaPayBanCardList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.options = res.data;
                    }
                })
            },
            getAdaPayBanCardPayList(){
                var that = this;
                var params = {};
                that.newApi.getAdaPayBanCardPayList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },
            spendCode(){
                var that = this;
                var params = {};
                params.payAmt = that.utils.keepTwoDecimalFull(that.input);
                params.tokenNo = that.value;

                that.newApi.alpplyBankCardPay(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("验证码发送成功");
                        that.orderNo = res.data;
                    }
                })
            },
            bankPay(){
                var that = this;
                var params = {};
                params.orderNo = that.orderNo;
                params.code = that.code;
                that.newApi.alpplyBankCardPayDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("支付成功");
                        that.getAdaPayBanCardPayList();
                    }
                })
            },

        }
    }
</script>

<style scoped>

</style>